import React, { useState, Fragment } from 'react'
import { observer } from 'mobx-react'

import { useShopStores } from 'shop/hooks/use-store'

import { CheckboxField } from '@elo-kit/components/form/checkbox-field/CheckboxField'
import { EloButton } from '@elo-kit/components/elo-button/EloButton'
import { TextField } from '@elo-kit/components/elo-ui/text-field/TextField'

import { convertToPercents } from 'utils/helpersShared.utils'

interface Props {
  isTemplatePreview?: boolean
  buttonStyle: {
    backgroundColor?: string
    color?: string
    borderRadius?: string
  }
  isLoading?: boolean
  coupon: {
    applied: boolean
    code: string
    data: {
      form: string
      recurring: boolean
      value: number
      percents: boolean
    }
  }
  isFree?: boolean
  applyCoupon: (coupon: string) => void
  withNextPayment?: boolean
  pricingPlan
  rates
}

export const SummaryCoupon: React.FC<Props> = observer(function SummaryCoupon(props) {
  const {
    buttonStyle,
    isTemplatePreview,
    coupon,
    isFree,
    applyCoupon,
    withNextPayment,
    pricingPlan,
    rates,
    isLoading,
  } = props
  const { currenciesStore, themeStore } = useShopStores()

  const [state, setState] = useState({
    showInput: false,
    value: '',
  })

  const checkChange = () => {
    setState({ ...state, showInput: !state.showInput })
  }

  const valueChange = (value) => {
    setState({ ...state, value })
  }

  const submitCoupon = () => {
    applyCoupon(state.value)
  }

  const couponData = coupon?.data
  const firstRate = rates?.[0]

  let discountSum = 0

  if (!isLoading && firstRate) {
    discountSum = firstRate.orderRatePrices.reduce((acc, currentVal) => {
      const { cfgs, discount } = currentVal.data
      const priceKey = cfgs.mustPayVat ? 'gross' : 'net'
      const nextDiscountSum = discount ? discount[priceKey] * discount.count : 0

      return acc + nextDiscountSum
    }, 0)
  }

  const couponValue = couponData?.percents
    ? convertToPercents(couponData?.value)
    : currenciesStore.convertToPrice(couponData?.value, pricingPlan?.currencyId)

  const color = themeStore.ppTemplate?.theme?.paymentPageColor
  const enabledCheckboxStyle =
    state.showInput && color
      ? {
          backgroundColor: color,
          borderColor: color,
        }
      : {}

  return (
    <Fragment>
      {((!isFree && couponData?.form) || isTemplatePreview) && (
        <div className='row coupon-check'>
          <div className='col-12'>
            <CheckboxField
              name='showInput'
              checked={state.showInput}
              label={I18n.t('react.shared.payment.coupon.label')}
              onChange={checkChange}
              style={enabledCheckboxStyle}
            />
          </div>

          {(state.showInput || isTemplatePreview) && (
            <div className='coupon-field'>
              <div className='coupon-code'>
                <TextField
                  className='code-input'
                  value={state.value}
                  onChange={valueChange}
                  placeholder={I18n.t('react.shared.payment.coupon.ph')}
                  required
                />
              </div>
              <div>
                <EloButton className='btn-coupon' disabled={!state.value} onClick={submitCoupon} style={buttonStyle}>
                  {I18n.t('react.shared.button.apply')}
                </EloButton>
              </div>
            </div>
          )}
        </div>
      )}
      {coupon?.applied && (
        <Fragment>
          <div className='coupon-applied'>
            <i className='fas fa-check' />
            {I18n.t('react.shared.coupon.applied')}
          </div>
          {discountSum !== 0 && (
            <Fragment>
              <div className='coupon-info' translate='no'>
                {I18n.t('react.shared.payment.coupon.info_coupon_new', {
                  code: coupon?.code,
                  value: couponValue,
                })}
              </div>
              {couponData?.recurring && withNextPayment && (
                <div className='coupon-info' translate='no'>
                  {I18n.t('react.shared.payment.coupon.info_recurring_new', { value: couponValue })}
                </div>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  )
})
