import React from 'react'
import { observer } from 'mobx-react'

import { useShopStores } from 'shop/hooks/use-store'

import { SummaryCoupon } from './SummaryCoupon'
import { SummaryPrices } from './SummaryPrices'

interface Props {
  isTemplatePreview?: boolean
  isLoading?: boolean
  pricingPlan: any // TODO
  rates: any // TODO
  isCouponApplied?: boolean
  additionalFee?: {
    title: string
  }
  countryCode?: string
  couponFormVisible?: boolean
  coupon?: any // TODO
  isFree?: boolean
  applyCoupon?: (coupon: string) => void
  withNextPayment?: boolean
  withoutStarNearPrice?: boolean
}

export const Summary: React.FC<Props> = observer(function Summary(props) {
  const {
    isTemplatePreview,
    couponFormVisible,
    isLoading,
    pricingPlan,
    isCouponApplied,
    additionalFee,
    countryCode,
    coupon,
    isFree,
    withNextPayment,
    applyCoupon,
    rates,
    withoutStarNearPrice,
  } = props
  const { themeStore } = useShopStores()

  const buttonStyle = themeStore && themeStore?.getApplyCouponBtnStyle ? themeStore?.getApplyCouponBtnStyle() : {}

  return (
    <div className='summary'>
      <div className='container-title'>{I18n.t('react.shared.payment.form.summary')}</div>
      {(couponFormVisible || isTemplatePreview) && (
        <SummaryCoupon
          buttonStyle={buttonStyle}
          isTemplatePreview={isTemplatePreview}
          rates={rates}
          applyCoupon={applyCoupon}
          withNextPayment={withNextPayment}
          coupon={coupon}
          isLoading={isLoading}
          isFree={isFree}
          pricingPlan={pricingPlan}
        />
      )}
      <SummaryPrices
        isLoading={isLoading}
        isTemplatePreview={isTemplatePreview}
        rates={rates}
        pricingPlan={pricingPlan}
        additionalFee={additionalFee}
        isCouponApplied={isCouponApplied}
        countryCode={countryCode}
        withoutStarNearPrice={withoutStarNearPrice}
      />
    </div>
  )
})
