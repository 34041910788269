import { DATE_FORMATS } from '@elo-kit/constants/dateTime.constants'
import { formatDateTime } from '@elo-kit/utils/dateTime.utils'

import { BANK_ACCOUNT_STATUSES, REQUIRED_PROFILE_FIELDS } from '../constants/profile.constants'
import { STATUSES } from '../constants/general.constants'
import { isEqualCH } from './countries.utils'

export const getProfileBirthday = (profile) =>
  profile && profile.birthday ? formatDateTime(profile.birthday, DATE_FORMATS.DDMMYYYY) : ''

/**
 * Unites firstName and lastName with separator
 * @param {Object} userProfile
 * @param {String} separator
 * @returns {String} fullName
 */
export const getProfileFullName = (userProfile = {}, separator = ' ') => {
  const { firstName = '', lastName = '' } = userProfile || {}
  return [firstName, lastName].filter(Boolean).join(separator)
}

/**
 * Unites all params related to address into one string
 * @param {Object} data
 * @param {Array} countriesList
 * @param {String} separator
 * @param {Array} states
 * @returns {String} address
 */
export const getUserAddress = (data = {}, countriesList = [], separator = ', ', states = []) => {
  const { street, streetNumber = '', zip, city, countryCode, state } = data || {}
  const country = countriesList.find(({ id }) => id === countryCode) || {}
  const countryState = states.find(({ id }) => id === state) || {}

  const userAddress = []
  if (street || streetNumber) userAddress.push(`${street} ${streetNumber}`)
  if (zip || city) userAddress.push(`${zip} ${city}`)
  if (country.name) userAddress.push(`${country.name}`)
  if (countryState.name) userAddress.push(`${countryState.name}`)

  return userAddress.map((item) => item.trim()).join(separator)
}

export const separateFullName = (fullName) => {
  const stringArray = fullName.split(/(\s+)/)
  return {
    firstName: stringArray[0],
    lastName: stringArray[2],
  }
}

export const getProfileLabel = (profile = {}) =>
  `${profile.company ? `${profile.company} / ` : ''}${getProfileFullName(profile, ' ')}`

export const getProfileLocation = (profile = {}) =>
  `${profile.street || ''} ${profile.streetNumber || ''} ${profile.zip || ''} ${profile.city || ''}`

export const isProfileEUMember = ({ countryCode }, countries = []) => {
  const { euMember } = countries.find(({ alpha2 }) => alpha2 === countryCode) || {}
  const isSwitzerland = isEqualCH(countryCode)

  return euMember || isSwitzerland
}

export const isProfileCompleted = (profile, requiredFields = REQUIRED_PROFILE_FIELDS, countries) => {
  let isValid = requiredFields.every((name) => !!profile[name])
  if (countries) {
    isValid = isValid && isProfileEUMember(profile, countries)
  }
  return isValid
}

export const getBankAccountStatus = (status) => {
  switch (status) {
    case BANK_ACCOUNT_STATUSES.notSent:
    case BANK_ACCOUNT_STATUSES.waiting:
      return {
        status: STATUSES.requested,
        text: I18n.t('react.shared.state.pending_verification'),
      }
    case BANK_ACCOUNT_STATUSES.activated:
      return {
        status: STATUSES.active,
        text: I18n.t('react.shared.state.verified'),
      }
    case BANK_ACCOUNT_STATUSES.deactivated:
    case BANK_ACCOUNT_STATUSES.rejected:
    case BANK_ACCOUNT_STATUSES.error:
    default:
      return {
        status: STATUSES.canceled,
        text: I18n.t('react.shared.state.rejected'),
      }
  }
}
